/**
 * @flow
 */

import { requestSuggestOrgNode } from "../../shared/api/orgNodeAPI";
import {
  requestPCNEmployeeGroupSearchResults,
  requestRegionEmployeesSearchResults,
} from "../../shared/api/regionEmployeeAPI";
import type { AutoSuggestDefinition, AutoSuggestDefinitionMap } from "../../shared/utils/autoSuggestUtils";
import type { SimpleRegionEmployee } from "../../shared/models/employee";
import type { SimpleOrgNode } from "../../shared/models/orgNode";

/* eslint-disable i18next/no-literal-string */
const EmployeeAutoSuggestDefinition: AutoSuggestDefinition<SimpleRegionEmployee> = {
  ariaLabelledBy: "auto-suggest employee by name or employee number",
  resultKeyFn: (result: SimpleRegionEmployee) => result.id,
  resultDisplayFn: (result: SimpleRegionEmployee) =>
    `${result.name} (${result.externalEmployeeNumber}) (${result.activeStatus})`,
  lookupFn: requestRegionEmployeesSearchResults,
  cacheKey: "cache-key-employees",
};

const OrgNodeReferencePathAutoSuggestDefinition: AutoSuggestDefinition<SimpleOrgNode> = {
  ariaLabelledBy: "auto-suggest org node by reference path",
  resultKeyFn: (result: SimpleOrgNode) => result.referenceID,
  resultDisplayFn: (result: SimpleOrgNode) => `${result.referencePath}`,
  lookupFn: requestSuggestOrgNode,
  cacheKey: "cache-key-orgnode",
};

const PCNAutoSuggestDefinition: AutoSuggestDefinition<SimpleRegionEmployee[]> = {
  ariaLabelledBy: "auto-suggest employee group by name or position control number",
  // gets results like "123,5005,456,5443"
  resultKeyFn: (results: SimpleRegionEmployee[]) => results.map((employee) => employee.id).join(","),
  // displays results like "Position 13 (4 employees): Cloud (123), Aerith (5005), Tifa (456), Barret (5443)"
  resultDisplayFn: (results: SimpleRegionEmployee[]) => {
    // position number is not unique -- we assume if the first member of this group has the PCN, they all do
    const positionNumber = results[0]?.positionNumber;
    return `Position ${positionNumber} (${results.length} employees): ${results
      .map((emp) => `${emp.name} (${emp.externalEmployeeNumber})`)
      .join(", ")}`;
  },
  lookupFn: requestPCNEmployeeGroupSearchResults,
  cacheKey: "cache-key-pcn-employee-groups",
};
/* eslint-enable i18next/no-literal-string */

export const AUTO_SUGGEST_TYPES_MAP: AutoSuggestDefinitionMap = {
  employee: EmployeeAutoSuggestDefinition,
  org_node: OrgNodeReferencePathAutoSuggestDefinition,
  position_control_number: PCNAutoSuggestDefinition,
};
